import React, { useEffect, useState } from 'react';

const Przepis = () => {
  const [recipeName, setRecipeName] = useState('');
  const [recipe, setRecipe] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const recipeNameFromURL = params.get('name');
    setRecipeName(recipeNameFromURL);

    if (recipeNameFromURL) {
      fetch(`https://dev.rybak.tech/api/recipes?name=${recipeNameFromURL}`)
        .then(response => response.json())
        .then(data => setRecipe(data))
        .catch(error => console.error('Błąd pobierania danych:', error));
    }
  }, [recipeName]);

  return (
    <>
      {recipe && (
        <section className="border border-dark p-3 m-1 mt-5 section-1 mx-auto" style={{ maxWidth: '80%' }}>
          <h2 className="recipe-title text-center">{recipe.title}</h2>
          <hr />
          <h3 className="ingredients-title">Składniki:</h3>
          <ul className="ingredients-list">
            {recipe.ingredients.map(ingredient => (
              <li key={ingredient._id}>
                {ingredient.name}: <span className={getQuantityColorClass(ingredient.quantity)}>{ingredient.quantity}</span>
              </li>
            ))}
          </ul>
          <hr />
          <h3 className="recipe-title">Kroki: </h3>
          <ol className="recipe-list">
            {recipe.instructions.split('. ').map((sentence, index) => (
              sentence.trim() !== '' && <li key={index}><p>{sentence.trim()}.</p></li>
            ))}
          </ol>
        </section>
      )}
    </>
  );
};

const getQuantityColorClass = (quantity) => {
  const parsedQuantity = parseInt(quantity);
  if (parsedQuantity < 50) {
    return 'text-success';
  } else if (parsedQuantity <= 100) {
    return 'text-warning';
  } else {
    return 'text-danger';
  }
};

export default Przepis;
