import React, { useState } from 'react';

export default function RecipeList({ recipesArray, searched, handleInputChange, ingredients, handleIngredientChange }) {
  const [mealSearch, setMealSearch] = useState(''); // Dodajemy stan dla wyszukiwania po nazwie potrawy

  const { selectedIngredients, filteredIngredients } = ingredients;

  // Filtrujemy przepisy na podstawie wprowadzonej nazwy potrawy
  const filteredRecipes = recipesArray.filter(recipe =>
    recipe.title.toLowerCase().includes(mealSearch.toLowerCase())
  );

  return (
    <>
      <main className="container-fluid">
        <div className="row">
          <aside className="col-md-3 p-4">
            <h3>Search Parameters</h3>
            <input
              type="text"
              className="form-control mb-3"
              value={searched}
              onChange={handleInputChange}
              placeholder="Składniki"
            />
            <div id="selected_ingredients" className="custom-scrollbar">
              {selectedIngredients.map((ingredient, index) => (
                <div key={index} className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={ingredient}
                    checked={ingredients[ingredient]}
                    onChange={(event) => {
                      event.stopPropagation(); // Stop event propagation
                      handleIngredientChange(ingredient);
                    }}
                  />
                  <label className="form-check-label" htmlFor={ingredient}>{ingredient}</label>
                </div>
              ))}
              {filteredIngredients.map((ingredient, index) => (
                <div key={selectedIngredients.length + index} className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={ingredient}
                    checked={ingredients[ingredient]}
                    onChange={() => handleIngredientChange(ingredient)}
                  />
                  <label className="form-check-label" htmlFor={ingredient}>{ingredient}</label>
                </div>
              ))}
            </div>
            <hr />
            <button type="submit" className="btn btn-primary">Szukaj</button>
          </aside>
          <section className="col-md-9 p-4">
            <input
              type="text"
              className="form-control mb-3"
              value={mealSearch}
              onChange={(e) => setMealSearch(e.target.value)}
              placeholder="Nazwa potrawy"
            />
            <div className="row">
              {filteredRecipes.map(recipe => (
                <a
                  key={recipe._id}
                  href={`/recipes?name=${encodeURIComponent(recipe.title)}`}
                  target="_blank"
                  rel="noreferrer"
                  className="col-sm-6 recipe-item-link"
                >
                  <figure className="col-sm-6 recipe-item">
                    <section className="border border-dark p-3 m-1">
                      <h3 className="recipe-title">{recipe.title}</h3>
                      <hr />
                      <ul className="ingredients-list">
                        {recipe.ingredients.map((ingredient, index) => (
                          <li key={index}>
                            {ingredient.name}: {ingredient.quantity}
                          </li>
                        ))}
                      </ul>
                      <p className="recipe-description">{recipe.instructions}</p>
                    </section>
                  </figure>
                </a>
              ))}
            </div>
          </section>
        </div>
      </main>
    </>
  );
}
