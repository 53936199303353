import React, { useState, useEffect } from 'react';
import Logowanie from './Logowanie.js';
import Panel from './Panel.js';
import RecipeList from './RecipeList.js';
import Przepis from './Przepis.js';
import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

export default function App() {
  const [recipes, setRecipes] = useState([]);
  const [searched, setSearched] = useState('');
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [ingredients, setIngredients] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [initialIngredients, setInitialIngredients] = useState({});
  const [recipesArray, setRecipesArray] = useState([]);

  useEffect(() => {
    fetchIngredients();
    fetchRecipes();
  }, []);

  useEffect(() => {
    filterRecipes();
  }, [ingredients]);

  useEffect(() => {
    filterIngredients();
  }, [searched]);

  const fetchIngredients = async () => {
    console.log("fetching ingredients");
    try {
      const response = await fetch('https://dev.rybak.tech/api/ingredients');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();   
      const initialIngredients = {};
      data.forEach(ingredient => {
        initialIngredients[ingredient] = false;
      });
      setIngredients(initialIngredients);
      setInitialIngredients(initialIngredients);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching ingredients:', error);
    }
  };
  
  const fetchRecipes = async () => {
    try {
      const response = await fetch('https://dev.rybak.tech/api/recipes');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setRecipes(data);
    } catch (error) {
      console.error('Error fetching recipes:', error);
    }
  };

  const handleInputChange = (e) => {
    setSearched(e.target.value);
  };

  const handleIngredientChange = (ingredient) => {
    setIngredients(prevState => ({
      ...prevState,
      [ingredient]: !prevState[ingredient]
    }));
  };

  const filterRecipes = async () => {
    try {
      const selectedIngredients = Object.keys(ingredients).filter(ingredient => ingredients[ingredient]);
      const response = await fetch(`https://dev.rybak.tech/api/recipes/names?ingredients=${selectedIngredients.join('|')}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setRecipesArray(data);
    } catch (error) {
      console.error('Error fetching filtered recipes:', error);
    }
  };

  const filterIngredients = () => {
    const filteredData = Object.keys(initialIngredients).reduce((acc, ingredient) => {
      if (ingredient.toLowerCase().includes(searched.toLowerCase()) || ingredients[ingredient]) {
        acc[ingredient] = ingredients[ingredient];
      }
      return acc;
    }, {});
  
    setIngredients(filteredData);
  };

  const selectedIngredients = Object.keys(ingredients).filter(ingredient => ingredients[ingredient]);
  const filteredIngredients = Object.keys(ingredients).filter(ingredient => !ingredients[ingredient] && ingredient.toLowerCase().includes(searched.toLowerCase()));

  const router = createBrowserRouter([{
    path: '/',
    element: <RecipeList
      searched={searched}
      handleInputChange={handleInputChange}
      ingredients={{...ingredients, selectedIngredients, filteredIngredients}}
      handleIngredientChange={handleIngredientChange}
      selectedRecipe={selectedRecipe}
      recipesArray={recipesArray}
    />,
    errorElement: <h1>Ta strona nie istnieje :(</h1>
  },{
    path: '/panel',
    element: <Panel />
  },{
    path: '/logowanie',
    element: <Logowanie />
  },{
    path: '/recipes',
    element: <Przepis />
  }

  ]);
  
  return (
    <div>
      <header className="p-3 d-flex justify-content-between align-items-center">
        <h1 className="m-0 mx-auto">Cookbirint</h1>

      </header>
      <RouterProvider router={router} />
    </div>
  );
}

/*         <a href="/logowanie" className="login-icon d-inline-block">
          <img src="/assets/login_icon.png" alt="Login" className="img-fluid" style={{ maxHeight: '50px' }} />
        </a> */
